@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #2c699a;
  --color-primary-100: #e3eef7;
  --color-secondary: #0db39e;
  --color-secondary-100: #dbf8f4;
  --color-tertiary: #ffb400;
  --color-tertiary-100: rgb(255, 239, 198);
  --color-tertiary-200: #f7efdb;
  --color-muted: #888888;
}

@layer components {
  .paymentInput {
    @apply text-sm leading-none text-gray-900 py-4 px-3 focus:border-blue-700 mt-1 bg-gray-200 border rounded-none border-gray-200;
  }
  .paymentLabel {
    @apply text-base font-semibold leading-none text-gray-800 mb-2;
  }
  .validate {
    @apply text-red-700 text-xs italic border-red-600;
  }
}

.layout {
  min-height: 100vh !important;
}
