* {
  box-sizing: border-box;
}
body {
  font-family: 'Poppins', sans-serif;
}
nav a.active {
  font-weight: 700;
  color: black;
  text-decoration: underline;
}
.box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 29px rgba(44, 105, 154, 0.3);
  border-radius: 20px;
}
.inner-box {
  box-shadow: 0px 3.55694px 34.3837px rgba(59, 74, 116, 0.135473);
  border-radius: 15.4134px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
}

.inner-box-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}
.hero-button {
  background: linear-gradient(90deg, #2c699a 10.32%, #0db39e 95.88%);
}
.hover-state:hover {
  background: linear-gradient(90deg, #2c699a 10.32%, #0db39e 95.88%);
  border-radius: 14.5474px;
  color: #ffffff;
  font-weight: 500;
  border: white;
}
.accordion-backgroundColor {
  background: linear-gradient(90deg, #2c699a 10.32%, #0db39e 95.88%);
  border-radius: 13px;
}
@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 7px;
    /* overflow-x: hidden; */
    background-color: rgb(37 99 235);
    display: none !important;
  }
}
/* .blog-content {
 text-align: justify; 
} */
.blog-content ul {
  list-style-type: disc;
  margin-left: 16px;
}
.blog-content p {
  margin-bottom: 1rem;
  font-size: 16px;
}
.blog-content h1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 0.5rem;
}
.blog-content h2 {
  font-weight: 700;
  font-size: 1.5rem;
}
.blog-content h3 {
  font-weight: 700;
  font-size: 1.3rem;
}
.blog-content h4 {
  font-weight: 700;
  font-size: 1rem;
}
.blog-content h5 {
  font-weight: 700;
  font-size: 0.8rem;
}
.blog-content h6 {
  font-weight: 700;
  font-size: 0.7rem;
}
.blog-content a {
  color: #2c699a;
}
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
}
.minH {
  min-height: 350px !important;
}
@media screen and (min-width: 768px) {
  .numbers {
    font-style: normal;
    font-weight: 400;
    font-size: 69.1696px;
    line-height: 95px;
    color: #ffffff;
  }
  .inner-box-text {
    font-size: 20px;
    line-height: 130%;
    max-width: 600px;
  }
}
@media screen and (max-width: 768px) {
  /* .blog-content {
    text-align: left;
  } */
  .blog-content h1 {
    font-size: 1.75rem;
  }
}
