.markdown {
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section {
    display: block;
  }
  summary {
    display: list-item;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }
  progress {
    vertical-align: baseline;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  [hidden],
  template {
    display: none;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: bolder;
  }
  dfn {
    font-style: italic;
  }
  mark {
    background: #ff0;
    color: #000;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  figure {
    margin: 1em 40px;
  }
  img {
    border-style: none;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  button,
  input,
  select,
  textarea,
  optgroup {
    font: inherit;
    margin: 0;
  }
  optgroup {
    font-weight: bold;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }
  legend {
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  textarea {
    overflow: auto;
  }
  [type="checkbox"],
  [type="radio"] {
    padding: 0;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  /* End extract */
  html,
  body {
    font-family: Verdana, sans-serif;
    font-size: 15px;
    line-height: 1.5;
  }
  html {
    overflow-x: hidden;
  }
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  .w3-serif {
    font-family: serif;
  }
  .w3-sans-serif {
    font-family: sans-serif;
  }
  .w3-cursive {
    font-family: cursive;
  }
  .w3-monospace {
    font-family: monospace;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 400;
    margin: 10px 0;
  }
  .w3-wide {
    letter-spacing: 4px;
  }
  hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }
  .w3-image {
    max-width: 100%;
    height: auto;
  }
  img {
    vertical-align: middle;
  }
  a {
    color: inherit;
  }
  a:link {
    color: rgb(53, 53, 255);
    text-decoration: underline;
  }
  a:visited {
    color: rgb(53, 53, 255);
    text-decoration: underline;
  }
  a:link:active {
    color: rgb(53, 53, 255);
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  li {
    display: list-item;
  }
}
